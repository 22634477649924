<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      :fields="fields"
      :row-data="row_data"
      post-api="/bedfloor/AddFloor"
      finish-router="bedfloor"
      permission="Bed_Floor_Create"
    >
      <template #facility="scope">
        <slot-facility :company-id="scope.rowData.companyID" :facility-id="scope.rowData.facilityID" :row-data="scope.rowData" :is-edit="scope.isEdit" />
      </template>

      <template #room="scope">
        <SlotRoom :row-data="scope.rowData" :is-edit="scope.isEdit" :slotConfig="scope.slotConfig"/>
      </template>
    </edit-field>


  </page-content>
</template>

<script>
import fields, { reloadFieldData } from "@/views/floor/fields";
import EditField from "@/components/EditField";
import EditFieldHeader from "@/components/EditFieldHeader";
import PageContent from "@/components/PageContent";
import SlotRoom from "@/views/floor/SlotRoom";
import common from "@/common";
import SlotFacility from "@/views/floor/SlotFacility";


export default {
  name: "Add",
  components: {
    SlotFacility,
    SlotRoom,
    PageContent,
    EditFieldHeader,
    EditField,
    fields
  },
  data() {
    let companyID = common.getCompanyId();
    let facilityID = common.getFacilityId();

    const query = this.$route.query;
    if (typeof query.companyID !== "undefined") {
      // refreshing the page will make the number value of query into string
      companyID = typeof query.companyID === "string" ? parseInt(query.companyID) : query.companyID
    }
    if (typeof query.facilityID !== "undefined") {
      // refreshing the page will make the number value of query into string
      facilityID = typeof query.facilityID === "string" ? parseInt(query.facilityID) : query.facilityID
    }

    return {
      title: common.getMenuName('bedfloor') + ' - ' + common.getI18n('common.add'),
      fields,
      // 如果是checkbox，里面写 field: [], 如ids: []
      row_data: {
        companyID,
        facilityID,
        rooms: []
      }
    }
  },
  created() {
    reloadFieldData()
  },
}
</script>

<style scoped>

</style>
